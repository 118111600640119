
import dayjs from 'dayjs';
import Common from '~/mixins/common'
import _ from 'lodash';
import { form_config } from '~/utils/models'
import { httpClient } from '~/utils/axios'
import { clientStatusOptions, planTypeOptions, reserveUnitOptions, sexOptions, subscTypeOptions, listExtendForMonthlyOptions, nextSubscTicketEndStyles, cancelTypes } from '~/utils/models'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import ClientCard from '~/components/common/client/Card'
import ClientBank from '~/components/common/client/Bank'
import ClientEditBank from '~/components/common/client/EditBank'
import ClientCustomInput from '~/components/common/client/CustomInput'
import ImageFileInput from '~/components/util/ImageFileInput'
import Tabs from '~/components/util/Tabs'
import ClientCart from '~/components/common/client/Cart'
import ClPaymentkaikei from '~/components/common/cl_payment/Kaikei'

export default {
  mixins: [Common],
  components: {
    DocumentInputs,
    ClientCard,
    ClientBank,
    ClientEditBank,
    ImageFileInput,
    Tabs,
    ClientCustomInput,
    ClientCart,
    ClPaymentkaikei,
  },
  props: {
  },
  data() {
    return {
      img_icon: null,

      showEditClientModal: false,      
      original_client: null,
      edit_client: null,
      edit_client_change: false,
      tabIndex: 0,

      planTypeOptions: planTypeOptions,
      reserveUnitOptions: reserveUnitOptions,
      statusOptions: clientStatusOptions,
      sexOptions: sexOptions,
      subscTypeOptions: subscTypeOptions,
      listExtendForMonthlyOptions: listExtendForMonthlyOptions,
      nextSubscTicketEndStyles: nextSubscTicketEndStyles,
      handleChangeNextSubscDate: false,
      handleChangeTicket: false,
      handleChangeCourse: false,
      handleChangeThisTermCancelSize: false,

      tabOptions: [
        {label: "プロフィール", value: 0, disabled: false},
        {label: "会員設定", value: 1, disabled: false},
        // {label: "目標", value: 2, disabled: false},
        {label: "各記録", value: 3, disabled: false},
        {label: "チケット・予約", value: 4, disabled: false},
        {label: "カート・定期購入", value: 5, disabled: false},
        {label: "掘り起こし", value: 6, disabled: false},
        {label: "支払い情報", value: 7, disabled: false},
      ],

      cancelTypes: cancelTypes,

      // 所属店舗
      showSelectBlongCompanyModal: false,
      newBlongCompanyUid: null,

      // 支払い待ち作成
      showShiharaimachiModal: false,
      shiharaimachiCreateType: null,
      shiharaimachiCreateOptions: [
        { value: "start_this_month", text: "当月開始" },
        { value: "start_next_month", text: "次月開始" },
        { value: "only_next_month", text: "次月分（継続利用時）" },
      ],
    }
  },
  async mounted() {
  },
  watch: {
    edit_client: {
      handler( newData, oldData ){
        console.log("watch edit_client_change", newData)
        this.edit_client_change = true
      },
      deep: true
    },
  },
  computed: {
    // reserveSettingDisabled(){
    //   return this.edit_client.course_setting_use
    // },
    // このコースに人が購入可能なコース
    byableCourses(){
      // use_new_versionかつreserve_typeが同じもの
      return _.filter(this.courses, (c) => {
        return c.use_new_version === true && c.reserve_type === this.edit_client.reserve_type
      })
    },
    selectedPaymentMethod(){
      return _.find(this.payment_methods, {id: this.edit_client.payment_method_id})
    }, 
    blong_to_company_name(){
      const selectedCompany = _.find(this.companies, {id: this.edit_client.company_id})
      return selectedCompany ? selectedCompany.name : "-"
    },

    // 支払い待ち作成タイプ
    shiharaimachiBeforeBaseDate(){
      let base_date = process.env.SHIHARAIMACHI_GENERATE_DATE
      // base_dateには1~30の数字が入っています。
      // 毎月の今日の月ではなく日付の部分を取得
      let today = dayjs().date()  
      // todayがbase_dateよりも前の日付ならbefore_base_date = true
      let before_base_date = today < base_date
      return before_base_date
    },
    shiharaimachiCreateTypeText(){
      let base_date = process.env.SHIHARAIMACHI_GENERATE_DATE
      if(this.shiharaimachiCreateType === "start_this_month"){
        if(this.shiharaimachiBeforeBaseDate){
          return `基準日(${base_date}日)前なので当月分のみ作成します`
        }else{
          return `基準日(${base_date}日)以降なので当月分・次月分を作成します`
        }
      }
      if(this.shiharaimachiCreateType === "start_next_month"){
        if(this.shiharaimachiBeforeBaseDate){
          return `基準日(${base_date}日)前なの何も作成しません`
        }else{
          return `基準日(${base_date}日)以降なので次月分のみ作成します`
        }
      }
      if(this.shiharaimachiCreateType === "only_next_month"){
        return `基準日(${base_date}日)関係なく来月分を１件作成します`
      }
    },
  },
  methods: {
    changeTab(event){
      console.log("changeTab",event)
      let self = this
      if(this.edit_client_change === true){
        self.$bvModal.msgBoxConfirm('変更した内容が保存さていませんがタブを移動してよろしいですか？', {
          okTitle: 'はい',
          cancelTitle: 'いいえ',
          centered: true,
        }).then(async confirm => {
          if(confirm){
            this.edit_client = _.cloneDeep(this.original_client)
            setTimeout(()=>{
              this.edit_client_change = false
            }, 200)
            this.tabIndex = event.newTabIndex
          }else{
            this.$refs.tabs.currentTab = event.oldTabIndex
            this.tabIndex = event.oldTabIndex
          }
        })
      }else{
        this.tabIndex = event.newTabIndex
      }
    },

    // // コース設定優先変更
    // changeCourseSettingUse(e){
    //   if(e === false) return
    //   console.log("changeCourseSettingUse", e)
    //   const selectedCourse = _.find(this.courses, {id: this.edit_client.course_id})
    //   let canChange = true
    //   if(selectedCourse === null) canChange = false
    //   if(selectedCourse.use_new_version === false) canChange = false
    //   if(selectedCourse.only_for_buy === true) canChange = false
    //   if(selectedCourse.reserve_type !== this.edit_client.reserve_type) canChange = false
    //   if(canChange) return
    //   this.edit_client.course_setting_use = false
    //   window.storeCtl.commit("alert/setError", "予約設定を持った有効なコースが設定されていないためこの設定を有効にできません")
    // },

    updateData(client){
      this.edit_client = client
      this.$emit("updated", client)
    },

    async open(client, tab = "profile"){
      console.log("open",tab)

      // 他店舗の顧客情報は編集できません
      if(client.company_id !== this.company.id){
        window.storeCtl.commit("alert/setError", "他店舗の顧客情報は編集できません")
        return
      }

      this.edit_client = client
      if(tab === "profile") this.tabIndex = 0
      if(tab === "settings") this.tabIndex = 1
      if(tab === "goal") this.tabIndex = 2
      if(tab === "log") this.tabIndex = 3
      if(tab === "calendar") this.tabIndex = 4
      if(tab === "subscription") this.tabIndex = 5
      if(tab === "alert") this.tabIndex = 6
      if(tab === "card") this.tabIndex = 7
      this.handleChangeTicket = false
      this.handleChangeCourse = false
      this.handleChangeThisTermCancelSize = false
      await httpClient
        .get(`/cmp/floor/clients/${this.edit_client.uid}.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.original_client = _.cloneDeep(res.data.data.client)
            this.edit_client = res.data.data.client
            this.showEditClientModal = true
            setTimeout(()=>{
              this.edit_client_change = false
            }, 200)
          }else{
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    async update(){
      let self = this

      // 定期購入Onでオンライン支払いなら場合は次回購入日は明日以降である必要がある
      if(this.edit_client.use_subscriptions && this.edit_client.payment_method_id === null && dayjs(this.edit_client.next_subsc_date).isBefore(dayjs())){
        window.storeCtl.commit("alert/setError", "定期購入における次回購入日は明日以降でなければなりません")
        return
      }

      // form
      const formData = new FormData();
      // icon
      if(self.img_icon){
        formData.append("img_icon", self.img_icon)
      }else{
        // 画像削除
        if(!self.edit_client.img_icon) formData.append("img_icon", "null")    
      }
      // other
      let client_obj = JSON.stringify(self.edit_client)
      formData.append('client_obj', client_obj)
      self.$store.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/clients/${self.edit_client.uid}.json`, formData, form_config)
        .then(async (res) => {
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("auth/setClient", res.data.data.client)
            self.edit_client_change = false
            self.showEditClientModal = false
            self.$emit("updated", res.data.data.client)
            window.storeCtl.commit("alert/setSuccess", "更新しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    async changeZipcode(){
      let address = await this.searchAddress(this.edit_client.zipcode)
      if(address) this.edit_client.address = address
    },
    calculateAge() {
      if(!this.edit_client.birth_date) return
      // 生年月日をDateオブジェクトに変換
      let birthDateObj = new Date(this.edit_client.birth_date);
      // 現在の日付を取得
      let currentDate = new Date();
      // 生まれてからの経過時間をミリ秒で計算
      let elapsedTime = currentDate - birthDateObj;
      // ミリ秒から年齢に変換
      let age = Math.floor(elapsedTime / (1000 * 60 * 60 * 24 * 365.25));
      this.edit_client.age = age
    },
    copyToEveryMonth(){
      this.edit_client.stock_lesson_size = this.edit_client.monthly_stock_lesson_size
      this.edit_client.bougth_lesson_size = this.edit_client.monthly_stock_lesson_size
      this.edit_client.stock_lesson_size_next = this.edit_client.monthly_stock_lesson_size
      this.edit_client.bougth_lesson_size_next = this.edit_client.monthly_stock_lesson_size
      this.edit_client.stock_lesson_size_next2 = this.edit_client.monthly_stock_lesson_size
      this.edit_client.bougth_lesson_size_next2 = this.edit_client.monthly_stock_lesson_size
    },
    changeSubscType(){
      console.log("changeSubscType")
      
      // 各ルール設定
      let subsc_type = this.edit_client.subsc_type
      let subsc_base_date = this.edit_client.subsc_base_date
      if(!this.company.allow_subsc_rule_to_client){
        subsc_type = this.company.subsc_type
        subsc_base_date = this.company.subsc_base_date
      }

      if(subsc_type != 'pay_at_first' && subsc_base_date === null) return
      let next_subsc_date = null
      // 1日
      if(subsc_type === 'pay_at_first'){
        next_subsc_date = dayjs().add(1, "M").format("YYYY-MM-01")
      }
      // 日付指定
      if(subsc_type === 'pay_at_date'){
        let base_date = dayjs()
        // 購入日が来月なら
        if(dayjs().format("D") >= subsc_base_date){
          base_date = dayjs().startOf('month').add(1, "M")
        }
        // 翌月同日付
        next_subsc_date = base_date.format(`YYYY-MM-${subsc_base_date.toString().padStart( 2, '0')}`)
        // 次月はその日付がない場合は最終日
        let isValid = dayjs(next_subsc_date).format('YYYY-MM-DD') === next_subsc_date
        if(!isValid){
          next_subsc_date = dayjs().endOf('month').format("YYYY-MM-DD")
        }
      }
      // 期間指定
      if(subsc_type === 'pay_with_term'){
        next_subsc_date = dayjs().add(subsc_base_date, "d").format("YYYY-MM-DD")
      }
      this.edit_client.next_subsc_date = next_subsc_date
    },

    // 支払い実行 -----------------------------------------------------
    // 支払待作成
    newClPaymentRequest(){
      this.shiharaimachiCreateType = null
      this.showShiharaimachiModal = true
    },
    async createBulkClPaymentRequest(){
      let generate_this_month = false
      let generate_next_month = false
      if(this.shiharaimachiCreateType === "start_this_month"){
        if(this.shiharaimachiBeforeBaseDate){
          generate_this_month = true
        }else{
          generate_this_month = true
          generate_next_month = true
        }
      }
      if(this.shiharaimachiCreateType === "start_next_month"){
        if(this.shiharaimachiBeforeBaseDate){
        }else{
          generate_next_month = true
        }
      }
      if(this.shiharaimachiCreateType === "only_next_month"){
        generate_next_month = true
      }
      // 当月分作成
      if(generate_this_month){
        await this.createClPaymentRequest("this_month")
      }
      // 次月分作成
      if(generate_next_month){
        await this.createClPaymentRequest("next_month")
      }

    },
    async createClPaymentRequest(target = "next_month"){
      let self = this
      self.$store.commit("loading/start", true)
      await httpClient
        .post(`/cmp/floor/clients/${self.edit_client.uid}/cl_payment_request.json`, {
          payment_method_id: self.edit_client.payment_method_id,
          cart: self.edit_client.subsc_settings.current_cart,
        })
        .then(async (res) => {
          if(target === "next_month") self.showShiharaimachiModal = false
          if (res.data.status === 'success') {
            self.edit_client.subsc_settings.current_cart = _.cloneDeep(res.data.data.client.subsc_settings.current_cart)
            self.$emit("updated", self.edit_client)
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setSuccess", "支払待を作成しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    // 支払い処理
    async submitPay($event){
      let self = this
      self.$store.commit("loading/start", true)
      await httpClient
        .post(`/cmp/floor/cl_payments.json`, {
          payment_type: "cart",
          payment_with_online: $event.payment_with_online,
          card_payment_number: $event.card_payment_number,
          cl_payment: {
            azukari_price: $event.azukari_price,
            otsuri_price: $event.otsuri_price,
          },
          payment_method_id: $event.payment_method_id,
          client_uid: self.edit_client.uid,
          cart: self.edit_client.subsc_settings.current_cart,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.edit_client = _.cloneDeep(res.data.data.client)
            self.$emit("updated", self.edit_client)
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setSuccess", "支払いをしました")
            this.$refs.kaikei.closeModal()
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },

    // ファイル------
    docsUpdated(new_docs){
      // console.log("docsUpdated", new_docs)
      this.edit_client.docs = new_docs
      this.edit_client.settings.cp_doc_ids = _.map(new_docs, "id")
    },
    
    // カード削除
    async deleteCard(){
      let self = this
      self.$bvModal.msgBoxConfirm('顧客のカード情報を削除してよろしいですか？', {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
            .put(`/cmp/floor/clients/${this.edit_client.uid}/remove_card.json`)
            .then(async (res) => {
              if (res.data.status === 'success') {
                window.storeCtl.commit("loading/stop")
                self.edit_client = res.data.data.client
                self.$emit("updated", res.data.data.client)
                window.storeCtl.commit("alert/setSuccess", "カードを削除しました")
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })

    },

    //- ステータス変更
    changeStatus(){
      if(this.edit_client.status === "active" && this.edit_client.join_at === null) this.edit_client.join_at = dayjs().format("YYYY-MM-DD")
      if(this.edit_client.status === "disactive" && this.edit_client.destroy_at === null) this.edit_client.destroy_at = dayjs().format("YYYY-MM-DD")
    },

    // 所属店舗
    editBlongCompany(){
      this.showSelectBlongCompanyModal = true
      this.newBlongCompanyUid = null
    },
    async updateBlongCompany(){
      let self = this
      self.$bvModal.msgBoxConfirm('DGFT設定が違う店舗の場合はカード設定が空になります。', {
        title: '顧客の所属店舗を変更してよろしいですか？',
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
            .put(`/cmp/floor/clients/${this.edit_client.uid}/change_comopany.json`, {
              company_uid: this.newBlongCompanyUid,
            })
            .then(async (res) => {
              if (res.data.status === 'success') {
                window.storeCtl.commit("loading/stop")
                self.edit_client = res.data.data.client
                self.$emit("updated", res.data.data.client)
                window.storeCtl.commit("alert/setSuccess", "所属店舗を変更しました")
                self.showSelectBlongCompanyModal = false
                self.showEditClientModal = false
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
  },
}
